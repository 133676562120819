import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/observable/of';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {RESTService} from '../../services/rest.service';
import * as ClientOAuth2 from 'client-oauth2';

// Models
import {User} from '../../models/user.model';

@Injectable()
export class UserService extends RESTService<User> {
  private userArray: any[];
  public currentUser: User;
  public sentinelToken: string;
  private selectedUser: User;
  public _currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  public _sentinelToken: BehaviorSubject<string> = new BehaviorSubject(null);;

  constructor(_http: HttpClient, authService: NbAuthService) {
    super(_http, authService);

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token) {
        const JWTToken = new NbAuthJWTToken(token.getValue());
        //JWTToken.setValue(token.getValue());

        const tokenPayload = JWTToken.getPayload();
        this.setCurrentUser(tokenPayload.id);
        this.getSentinelAuth()
      }
    });
  }

  public setCurrentUser(currentUserId: number): void {
    this.getUserById(currentUserId).then(user => {
      this.currentUser = user;

      const data = {
        email: user.email,
        fullName: user.fullName,
        id: user.id,
        isActive: user.isActive,
        isAdmin: user.isAdmin,
        Companies: user.Companies,
        position: user.position,
      }

      localStorage.setItem('user', JSON.stringify(data))
      this._currentUser.next(user);
    });
  }

  public getSentinelAuth(): void {
    const sentinelAuth = new ClientOAuth2({
        clientId: '5298c5e9-dbed-488d-a399-61b39cc228e8',
        clientSecret: 'hdl]Ees}16~8D,(0R*/.VOIH52BGfVAVa[G.5w{;',
        accessTokenUri: 'https://services.sentinel-hub.com/oauth/token'
        });
    sentinelAuth.credentials.getToken()
    .then( user => {
      this.sentinelToken = user.accessToken
      this._sentinelToken.next(user.accessToken)
  })
  }

  public getUser(): Observable<User> {
    return Observable.of(this.currentUser);
  }

  // GET /users
  public getAllUsers(): Promise<User[]> {
    this.setApiEndpoint('/users');
    return this.getAll();
  }

  // POST /users
  public createUser(data): Promise<User> {
    this.setApiEndpoint('/users');
    return this.save(data);
  }

  // GET /users/logout
  public logout(): Promise<any> {
    this.setApiEndpoint('/users/logout');

    return new Promise(resolve => {
      const url: string = this.apiURL;
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authToken,
      });
      const options = {headers: headers};

      this.http.get(url, options).subscribe(
        result => {
          resolve(result);
          this._currentUser.next(null);
        },
        error => {
          throw new Error(error);
        },
      );
    });
  }

  // DELETE users/{userid}
  public deleteUser(userId: number): Promise<boolean> {
    if (userId === this.currentUser.id) {
      throw new Error('Can´t delete current user!');
    } else {
      this.setApiEndpoint('/users');
      return this.delete(userId);
    }
  }

  // GET users/{userid}
  public getUserById(id: number): Promise<User> {
    this.setApiEndpoint('/users');
    return this.get(id);
  }

  // PUT users/{userid}
  public updateUser(
    userId = this.selectedUser.id,
    data: any,
  ): Promise<Boolean> {
    this.setApiEndpoint('/users');
    const object = {
      id: data.id,
      fullName: data.fullName,
      position: data.position,
      profileImg: data.profileImg,
      isActive: data.isActive,
    };
    return this.update(object);
  }

  // GET InfoStudio
  public getInfoDashboards() {
    this.setApiEndpoint(
        '/InfoStudio'
    );

    return this.getAll();
}

  // Create new User
  public newResource(data: any): User {
    return new User(this, data);
  }

  public setSelectedUser(user) {
    this.selectedUser = user;
  }

  public getSelectedUser() {
    return Observable.of(this.selectedUser);
  }
}
