import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';

// Models
import { Polygon } from '../../models/polygon.model';
import { Subject } from 'rxjs';

@Injectable()
export class PolygonService extends RESTService<Polygon> {

    private token: any;
    private headers: any;
    private tokenPayload: any;
    private currentUserId: number;
    private pathScore = `https://3mv9snkw39.us-east-2.awsapprunner.com`;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    // JWTToken.setValue(token.getValue());

                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;

                    this.headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ token.getValue() }` 
                    });
                }
            });
    }

    private listPolygon: any;

    public setListPolygon(listPolygon: any): void {
        this.listPolygon = listPolygon;
    }

    public getListPolygon(): Observable<any> {
        return this.listPolygon;
    }

    public getDataUrlJSON() {
        return this.http.get('https://s3-agropro.s3.us-west-1.amazonaws.com/temp/mexicali.json');
    }

    // GET /poligono

    public getAllPolygon(
    userId = this.currentUserId,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/users/' +
            userId +
            '/measurements'
        );
        return this.getAll();
    }

    public AllPolygonv2(
    userId = this.currentUserId,
    pageNum: any,
    pageSize: any,
    order: any,
    groupname: any,
    ): Promise<any> {

        this.setApiEndpoint(
            '/datashow/user/' +
            userId +
            '/mispoligonos?page=' + pageNum + '&size=' + pageSize + order + groupname
            
        );
        
        return this.getOne();
    }

    public AllPolygonvNoPaginate(
        userId = this.currentUserId,
        filtro:any,
    ): Promise<any> {
    
        this.setApiEndpoint(
            '/datashow/user/' +
            userId +
            '/mispoligonos' + filtro
            
        );
        
        return this.getOne();
    }

    // GET /poligono by group

    public getAllPolygonByGroup(
    groupId: any,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/groups/' +
            groupId +
            '/measurements'
        );
        return this.getAll();
    }

    // POST /poligono
    public createPolygon(
        data: any
    ): Promise<Polygon> {

        this.setApiEndpoint(
            '/measurements'
        );

        return this.save(data);
    }

    public generateNDVIData( data: any ): Observable<any> {
        return this.http.post(`${ this.pathScore }/v1/measurements/ndvis`, data, { headers: this.headers });
    }

    // POST /poligono python
    public createPolygonPython( data: any ): Observable<any> {
        return this.http.post(`${ this.pathScore }/v1/measurements/score`, data, { headers: this.headers });
    }
    
    public recalculoPolygon( polygonId: any ): Observable<any> {
        return this.http.put(`${ this.pathScore }/v1/measurements/${polygonId}/metrics`, {}, { headers: this.headers });
    }

    // PUT /poligono
    public editPolygon(
        data: any
    ): Promise<Polygon> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.update(data);
    }

    // DELETE /poligono
    public deletePolygon(IdPolygon: number): Promise<boolean> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.delete(IdPolygon);
    }

    // GET ONE /poligono
    public GetPolygon(IdPolygon: number): Promise<any> {
        this.setApiEndpoint(
            '/measurements'
        );
        // return this.get(IdPolygon, true);
        return this.get(IdPolygon);
    }

    public CheckPolygons(
        polygon: any,
        inVector: any,
        inMsr: any,
        id: any,
    ): Promise<any> {

        let vector = '';
        let season = '';
        let setID = '';

        const ciclo = localStorage.getItem('ciclo');
        if(!inVector && !inMsr && id !== 0){
            setID = '&notInMsr=' + id;
        }

        if(inVector){ 
            vector = '&inVector=' + inVector; 
            season = '&season=' + ciclo; 
            if(id !== 0){
                setID = '&notInVec=' + id;
            }
        }

        let msr = '';

        if(inMsr){ 
            msr = '&inMsr=' + inMsr; 
            if(id !== 0){
                setID = '&notInMsr=' + id;
            }
        }

        this.setApiEndpoint(
            '/vectors/searchempalme?polygon=' + polygon + vector + msr + season + setID
        );
        return this.getOne();
    }

    public GetPolygonGeom(polygon: any): Observable<any> {
        return this.http.get(`https://ran.sinecta.com/polygons?geom=${JSON.stringify(polygon)}`);
    }

    public polygons_show:any;

    private sendDateSubject = new Subject<any[]>();
    sendDateObservable = this.sendDateSubject.asObservable();

    sendDate( selectedDate: any ) {
        this.sendDateSubject.next( selectedDate );
    }

    public newResource(data: any): Polygon {
        return new Polygon(this, data);
    }
}
